import React from 'react';
import styles from './Loader.module.css'

const Loader: React.FC = () => {
  return (
    <>
      <span className={styles.loaderParent}>
        <span className={styles.loader} ></span>
      </span>
    </>
  );
};

export default Loader;
