import React from "react";
import { ToastContainer } from "react-toastify";

const Toast: React.FC = () => {
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={true}
        theme="light"
        limit={1}
      />
      <ToastContainer />
    </div>
  );
};

export default Toast;
