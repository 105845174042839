import { FunctionComponent, useCallback, useState, useEffect } from "react";
import styles from "./MapPage.module.css";
import styles2 from "../Selected_Truck/ProductPage.module.css";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import { successToast } from "../../components/Toast/ToastMessage";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentUserData } from "../../store/actions/userAction";
import { useGlobalContext } from "../../components/Global/GlobalState";
import Login from "../../components/Login/Login";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PortalPopup from "./Portals/PortalPopup";
import { validateCurrentUser } from "../../components/Route/CheckAuth";
import GenericModal from '../../components/Modal/GenericModal';

interface MapPageNavbarProps {
  requiresBackButton?: boolean;
  isAdmin?: boolean;
  hideLogo?: boolean;
  isNotOnMapPage?: boolean | {};
}

const MapPageNavbar: FunctionComponent<MapPageNavbarProps> = ({
  requiresBackButton,
  isAdmin,
  hideLogo,
  isNotOnMapPage,
}) => {
  const reduxState = useSelector((state: any) => state);
  const currentUser = useSelector((state: any) => state.currentUser);
  const {
    searchString,
    setSearchString,
    searchByLocation,
    setSearchByLocation,
  } = useGlobalContext();

  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const [dropdownMenuMultiSelectOpen, setDropdownMenuMultiSelectOpen] =
    useState<boolean>(false);

  const openDropdownMenuMultiSelect = useCallback(() => {
    setDropdownMenuMultiSelectOpen((prevState) => !prevState);
  }, []);

  const location = useLocation();
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState<string | null>(
    localStorage.getItem("accessToken")
  );
  const [refreshToken, setrefreshToken] = useState<string | null>(
    localStorage.getItem("refreshToken")
  );
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [currentUrl, setCurrentUrl] = useState<string>(location.pathname);
  const fullNav =
    currentUrl.includes("main") || currentUrl.includes("organization");
  // (currentUrl.includes("truck") && !currentUrl.includes("add_truck"));

  useEffect(() => {
    setCurrentUrl(location.pathname);
    setDropdownMenuMultiSelectOpen(false);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [location, MapPageNavbar]);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    if (accessToken && refreshToken) {
      validateCurrentUser(accessToken, refreshToken)
        .then((validUser) => {
          console.log("validUser", validUser);
        })
        .catch((error) => {
          console.error("Error validating user:", error);
        });
    }
  }, []);

  const handleLogOut = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setAccessToken(null);
    dispatch(
      setCurrentUserData({
        emailVerification: {
          active: false,
        },
        phoneVerification: {
          active: false,
        },
        _id: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        role: "",
        active: false,
        organizations: [],
        trucks: [],
        additionalDocs: null,
        isBlocked: false,
        refreshTokens: [],
        createdAt: "",
        lastActivity: "",
        __v: 0,
      })
    );
    navigate("/main");
    successToast("Logged out");
    console.log(reduxState);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      navigate("/main");
    }
  };

  const [isHomePage, setIsHomePage] = useState<boolean>(false);

  useEffect(() => {
    console.log(window.location.hash);
    console.log("what");
    if (window.location.hash == "#/") {
      setIsHomePage(true);
    } else {
      setIsHomePage(false);
    }
  }, []);

  return (
    <>
      {!currentUrl.includes("registration") &&
        (!currentUrl.includes("admin") ||
          currentUrl.includes("admin/settings/profile")) && (
          <>
            {windowWidth < 850 ? (
              <Navbar expand="lg" className="bg-body-tertiary">
                <Container fluid={true}>
                  <Navbar.Brand href="#home">
                    <Link
                      to={
                        currentUser && currentUser.role == "renter"
                          ? "/admin/truck_management"
                          : "/main"
                      }
                    >
                      <img
                        className={styles.theLogoMakerBuiltFor1000}
                        alt=""
                        src="/logos/Truck4 logo-1.png"
                        onClick={() => {
                          window.location.href = "/#/main";
                        }}
                        role="button"
                      />
                    </Link>
                  </Navbar.Brand>

                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <div className="me-auto mt-2">
                      {accessToken && refreshToken ? (
                        <>
                          {currentUrl.includes("admin/settings/profile") ? (
                            <>
                              <Link to="/admin/settings/profile">
                                <b className={styles2.topNavButton}>Account</b>
                              </Link>
                              <Link to="/admin/settings/profile">
                                <b className={styles2.topNavButton}>
                                  Notifications
                                </b>
                              </Link>
                              <Link to="/admin/settings/profile">
                                <b className={styles2.topNavButton}>
                                  Business Information
                                </b>
                              </Link>
                              <Link to="/admin/settings/profile">
                                <b className={styles2.topNavButton}>
                                  Payment Methods
                                </b>
                              </Link>
                            </>
                          ) : (
                            <>
                              <Link to="/admin/truck_management">
                                <b className={styles2.topNavButton}>
                                  Dashboard
                                </b>
                              </Link>
                            </>
                          )}
                          <b
                            className={styles2.topNavButton}
                            onClick={handleLogOut}
                          >
                            Log Out
                          </b>
                        </>
                      ) : (
                        <>
                          <b
                            className={styles2.topNavButton}
                            onClick={() => setOpen(true)}
                          >
                            Log In
                          </b>
                        </>
                      )}
                      <Link
                        to={accessToken ? "/add_truck" : "#"}
                        onClick={accessToken ? undefined : () => setOpen(true)}
                      >
                        <b
                          className={`${styles.button}`}
                          style={{ width: "unset", color: "white" }}
                        >
                          To rent out
                        </b>
                      </Link>
                    </div>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            ) : (
              <div className={styles.alreadyHaveAccount}>
                <div
                  className={styles.frameParent}
                  style={{
                    backgroundColor: isNotOnMapPage ? "#f8fafc" : "white",
                  }}
                >
                  <div className={styles.basicInputParent}>
                    {fullNav && (
                      <>
                        <div className={styles.basicInput}>
                          <div className={styles.inputLeftContent}>
                            <img
                              className={styles.iconoutlinesearch}
                              alt=""
                              src="/generics//iconoutlinesearch1.svg"
                            />
                            <input
                              type="text"
                              className={`${styles.basicInput}`}
                              placeholder="Search"
                              value={searchString}
                              onChange={(e) => setSearchString(e.target.value)}
                              onKeyDown={handleKeyDown}
                            />
                          </div>
                        </div>
                        <div className={styles.basicInput1}>
                          <div className={styles.inputLeftContent}>
                            <input
                              style={{
                                top: 0,
                              }}
                              type="text"
                              className={`${styles.basicInput}`}
                              placeholder="City, State or Zip code"
                              value={searchByLocation}
                              onChange={(e) =>
                                setSearchByLocation(e.target.value)
                              }
                              onKeyDown={handleKeyDown}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className={styles.buttonParent}>
                    {!accessToken && !refreshToken && isHomePage && (
                      <div
                        className={styles.inputText2}
                        style={{ color: "black", margin: "auto" }}
                      >
                        Already have an account?
                      </div>
                    )}
                    {fullNav && (
                      <>
                        <Link
                          to={accessToken ? "/add_truck" : "#"}
                          onClick={
                            accessToken ? undefined : () => setOpen(true)
                          }
                        >
                          <b
                            className={`${styles.button} hoverButtons`}
                            style={{ width: "unset", color: "white" }}
                          >
                            To rent out
                          </b>
                        </Link>
                        <div className={`${styles.button1} hoverButtons`}>
                          <img
                            className={styles.iconoutlinebell}
                            alt=""
                            src="/generics//iconoutlinebell.svg"
                          />
                        </div>
                      </>
                    )}

                    <div className={`${styles.buttonGroup} ${styles.dropdown}`}>
                      {accessToken && refreshToken ? (
                        <div className={`${styles.button2} hoverButtons`}>
                          <div className={styles.photo}>
                            <div
                              className={styles.vecicleCardrectangle1271Child}
                            />
                            <div className={styles.vt}>VT</div>
                          </div>

                          <div className={styles.text1}>Profile</div>
                          <div className={styles.button3}>
                            <img
                              onClick={openDropdownMenuMultiSelect}
                              className={styles.iconoutlinebell}
                              alt=""
                              src="/generics//iconsolidmenu.svg"
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`${styles.inputTextGroup} hoverButtons`}
                        >
                          <div
                            className={styles.button2}
                            onClick={() => setOpen(true)}
                          >
                            <div className={styles.text1}>Log in</div>
                          </div>
                        </div>
                      )}

                      {accessToken && refreshToken && (
                        <div className={styles.dropdownParent}>
                          <div className={styles.dropdownContent}>
                            <Link to="/admin/truck_management">
                              <b>Dashboard</b>
                            </Link>
                          </div>
                          <div className={styles.dropdownContent}>
                            <Link to="">
                              <b onClick={handleLogOut}>Log Out</b>
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {!(hideLogo && windowWidth > 1250) && (
                    <Link
                      to={
                        currentUser && currentUser.role == "renter"
                          ? "/admin/truck_management"
                          : "/main"
                      }
                    >
                      <img
                        className={styles.theLogoMakerBuiltFor1000}
                        alt=""
                        src="/logos/Truck4 logo-1.png"
                        onClick={() => {
                          window.location.href = "/#/main";
                        }}
                        role="button"
                      />
                    </Link>
                  )}
                </div>
                {requiresBackButton && (
                  <div className={styles2.textButton}>
                    <img
                      className={styles2.iconoutlinecurrencyDollar}
                      alt=""
                      src="/generics//icon@2x.png"
                    />
                    <div className={styles2.reefer}>Back to All Vehicles</div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      <GenericModal
        cmp={Login}
        setShowModal={handleClose}
        onClickSubmit={handleClose}
        showModal={open}
        showFooter={false}
        title="Log In"
      />
    </>
  );
};

const style = {
  position: "absolute",
  overflow: "auto",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45vw",
  p: 4,
  "& > form > div": {
    width: "unset",
  },
  "& > form": {
    backgroundColor: "unset",
  },
  "& > div > div": {
    left: "0",
    width: "100%",
    margin: "auto",
  },
  "@media (max-width: 750px)": {
    width: "90%",
    maxHeight: "90vh",
    padding: "2rem",
  },
  "@media (max-width: 500px)": {
    width: "100%",
    maxHeight: "100vh",
    padding: "1rem",
  },
};

export default MapPageNavbar;
