import { FunctionComponent, useCallback } from "react";
import styles from "./css/PricingPagesTier1.module.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export interface AddTruckFirstPageProps {
  onNextButtonClick: () => void;
}

const PricingPagesTier1: FunctionComponent<AddTruckFirstPageProps> = ({
  onNextButtonClick,
}) => {
  const currentUser = useSelector((state: any) => state.currentUser);
  const navigate = useNavigate();

  const handleNextClick = () => {
    if (
      `${localStorage.getItem("accessToken")}` !== "" &&
      `${localStorage.getItem("refreshToken")}` !== ""
    ) {
      onNextButtonClick();
    } else {
      navigate("/login");
    }
  };

  return (
    <div className={styles.pricingPagestier}>
      <div className={styles.frameParent}>
        <div className={styles.frameWrapper}>
          <div className={styles.theLogoMakerBuiltFor1000Parent}>
            <Link to={currentUser && currentUser.role == 'renter' ? '/admin/truck_management' : '/main'} >
              <img
                className={styles.theLogoMakerBuiltFor1000}
                alt=""
                src="/logos/Truck4 logo-1.png"
                onClick={() => {
                  window.location.href = "/#/main";
                }}
              />
            </Link>
            <img
              className={styles.shapesIcon}
              loading="eager"
              alt=""
              src="/generics/shapes-1.svg"
            />
          </div>
        </div>
        <div className={styles.turnYourTruck}>
          { currentUser.role == "driver" ? "As a driver you cannot add vehicle, please request for role switch as vehicle-owner and add vehicle" : "Turn your truck into a stable income — rent it out with us!"}
        </div>
      </div>
      <div
        className={styles.supportingText}
      >Lorem ipsum dolor sit amet consectetur, adipisicing elit.&nbsp;</div>
      <div className={styles.pricingPagestierChild} />
      <div className={styles.content}>
        <button className={styles.button} onClick={handleNextClick}>
          <div className={styles.text12}>{ currentUser.role == "driver" ? "Switch role and add vehicle" : "Add a vehicle"}</div>
        </button>
      </div>
    </div>
  );
};

export default PricingPagesTier1;
