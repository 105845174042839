export const setHoveredTruckId = (truckId: string) => {
  console.log("Action Creator: SET_HOVERED_TRUCK_ID", truckId);
  return {
    type: "SET_HOVERED_TRUCK_ID",
    payload: truckId,
  };
};
// mapReducer.ts
const initialState = {
  hoveredTruckId: null,
};

const mapReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_HOVERED_TRUCK_ID":
      console.log("Reducer: SET_HOVERED_TRUCK_ID", action.payload);
      return { ...state, hoveredTruckId: action.payload };
    default:
      return state;
  }
};

export default mapReducer;
