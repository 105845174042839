import { FunctionComponent } from "react";
import styles from '../../../css/Registration_Driver/AddOrganizationList.module.css';
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import Loader from '../../../components/Loader/Loader';

interface AddOrganizationModalBodyProps {
  filteredOptions: any;
  inputValue: string;
  isDropdownOpen: boolean;
  requiredField: string;
  companyName: string;
  dataCompanies: Array<Company>;
  loading: boolean;
  handleInputChange: (value: any) => void;
  toggleDropdown: (value: any) => void;
  handleOptionSelect: (value: any) => void;
  handleCompanyNameChange: (value: any) => void;
  handleNextButtonClick: () => void;
  handleAddItClick: () => void;
  handleAccordionClick: (    
    name: string,
    address: string,
    companyEmail: string,
    phone: string,
    dot: string,
    item: any) => void;
}

interface Company {
  name: string;
  physicalAddress?: {
    street: string;
  };
}

const AddOrganizationModalBody: FunctionComponent<AddOrganizationModalBodyProps> = ({
  filteredOptions,
  inputValue,
  isDropdownOpen,
  requiredField,
  companyName,
  dataCompanies,
  loading,
  handleInputChange,
  toggleDropdown,
  handleOptionSelect,
  handleCompanyNameChange,
  handleNextButtonClick,
  handleAddItClick,
  handleAccordionClick,
}) => {

  return (
    <Modal.Body>
      <div>
        <div className={styles.textButtonGroup}>
            <div className={styles.textButton1}>
              <img className={styles.icon} alt="" src="/generics/icon6.svg" />
              <div className={styles.reefer}>Back&nbsp;</div>
            </div>
            <div className={styles.inputWithLabelParent}>
              <div className={styles.inputWithLabel}>
                <div className={styles.reefer}>State</div>
                <div className={styles.dropdown}>
                  <div className={styles.inputField} style={{ textAlign: "left" }}>
                    <input
                      type="text"
                      style={{ paddingLeft: 0 }}
                      placeholder="Select an option"
                      value={inputValue}
                      onChange={handleInputChange}
                      onClick={toggleDropdown}
                    />

                    <StyledButton
                      className={styles.dropdownButton}
                      style={{ top: "15%" }}
                      onKeyDown={() => {}}
                      role="button"
                      onClick={toggleDropdown}
                    >
                      <FontAwesomeIcon icon={faChevronDown} />
                    </StyledButton>
                    {isDropdownOpen && (
                      <div
                        className={styles.dropdownMenu}
                        style={{
                          background: "#F1F5F9",
                          maxHeight: "200px",
                          overflow: "auto",
                        }}
                      >
                        <Container>
                          {filteredOptions.map((option :any, index :number) => (
                            <Item
                              style={{ textAlign: "left" }}
                              className="hoverDropdown"
                              onClick={() => handleOptionSelect(option)}
                              key={index}
                            >
                              {option}
                            </Item>
                          ))}
                        </Container>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.inputWithLabel}>
                <div className={styles.reefer}>Company name&nbsp;</div>
                <div
                  className={styles.basicInput}
                  style={
                    requiredField === "companyName"
                      ? { border: "1px solid red" }
                      : {}
                  }
                >
                  <input
                    type="text"
                    className={styles.inputField}
                    value={companyName}
                    placeholder="Company name"
                    style={{ border: "none", paddingLeft: "0" }}
                    onChange={handleCompanyNameChange}
                  />
                </div>
              </div>
            </div>
            <div className={styles.frameChild} />

            <div
              className={`${styles.button1} hoverButtons`}
              onKeyDown={() => {}}
              role="button"
              onClick={() => handleNextButtonClick()}
            >
              <div className={styles.text}>Search company</div>
            </div>
            <div className={styles.inputTextContainer}>
              <div className={styles.inputText8}>Length</div>
              <div className={styles.tagParent}>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>Small</div>
                </div>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>Large</div>
                </div>
              </div>
            </div>
            <div className={styles.inputTextContainer}>
              <div className={styles.inputText8}>Lift Gate</div>
              <div className={styles.tagParent}>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>Rail Gate</div>
                </div>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>Tuck Under</div>
                </div>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>No Lift Gate</div>
                </div>
              </div>
            </div>
            <div className={styles.resultsFoundParent}>
              <div className={styles.resultsFound}>
                {dataCompanies.length} results found
              </div>
              <div
                className={styles.companyNotOnTheListParent}
                onKeyDown={() => {}}
                role="button"
                onClick={handleAddItClick}
              >
                <div className={styles.resultsFound}>Company not on the list?</div>
                <div className={styles.addIt}>+Add it</div>
              </div>
            </div>
            <div className={styles.frameParent}>
              <div className={styles.accordionParent} id={"listItems"}>
                {
                  loading ? (
                    <>
                      <Loader />
                    </>
                  ):(
                    <>
                      {dataCompanies.map((item: any) => {
                        return (
                          <div
                            key={item}
                            className={styles.accordion}
                            role="button"
                            onClick={() =>
                              handleAccordionClick(
                                item.name,
                                item.physicalAddress?.street,
                                item.email,
                                item.phone,
                                item.dotNumber,
                                item
                              )
                            }
                          >
                            <div className={styles.headlineParent}>
                              <div className={styles.headline}>{item.name}</div>
                            </div>
                            <div
                              className={styles.inputText1}
                              style={{ textAlign: "left" }}
                            >
                              {item.physicalAddress?.street}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )
                }
              </div>
            </div>
          </div>
      </div>
    </Modal.Body>
  )
}

export default AddOrganizationModalBody;

const StyledButton = styled.button`
  position: absolute;
  border: 0;
  right: 0;
  background-color: transparent;
`;

const Container = styled.div`
  border-radius: 8px;
  box-shadow: 0px 10px 22px 0px rgba(45, 77, 108, 0.15);
  background-color: var(--basic-white, #fff);
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  color: var(--neutral-900, #0f172a);
  white-space: nowrap;
  align-items: start;
  border-radius: 8px;
  background-color: var(--basic-white, #fff);
  width: 100%;
  justify-content: center;
  padding: 8px 60px 8px 16px;
  font: 400 14px/20px Inter, sans-serif;

  @media (max-width: 991px) {
    white-space: initial;
    padding-right: 20px;
  }
`;
