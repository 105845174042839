export const getNoAuthHeaders = () => {
  return {
    "Content-Type": "application/json",
  };
};

export const getAuthHeaders = () => {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");

  return {
    // "Content-Type": "application/json",
    "X-Access-Token": accessToken ? accessToken : "",
    "X-Refresh-Token": refreshToken ? refreshToken : "",
  };
};
