import { FunctionComponent } from "react";
import styles from "./css/AddTruckApplyToRent.module.css";
import MapPageNavbar from "../Map_Page/MapPageNavbar";

interface INavbarProps {
  isNotOnMapPage?: boolean;
}

const Navbar2: FunctionComponent<INavbarProps | any> = (isNotOnMapPage) => {
  return (
    <>
      <div className={styles.celesClubPVidiGruzovikhMaParent}>
        <img
          className={styles.theLogoMakerBuiltFor10001}
          loading="eager"
          alt=""
          src="/logos/Truck4 logo-1.png"
          onClick={() => {
            window.location.href = "/#/main";
          }}
        />

        <img
          className={styles.stockPhotoBigRigGrayBonneIcon}
          loading="eager"
          alt=""
          src="/generics/stockphotobigriggraybonnetsemitruckwithhighcabandlongrefrigeratorsemitrailertransporting2263359175removebgpreview@2x.png"
        />
        <div className={styles.inputTextParent}>
          <div className={styles.inputText}>
            Hire internationally with complete confidence
          </div>
          <div className={styles.inputText1}>
            We'll handle your worldwide compliance, payroll, and benefits, so
            you can fast-track your international expansion.
          </div>
        </div>
      </div>
      <MapPageNavbar hideLogo={true} isNotOnMapPage={isNotOnMapPage} />
    </>
  );
};

export default Navbar2;
