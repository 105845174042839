import React, { useState } from "react";
import styles from "../../css/Registration_Driver/AddOrganizationDetails2.module.css";
import { PropsFromReduxVechicleToRent } from "./VechicleToRent";
import { RegistrationData } from "@/src/store/types";
import { useSelector } from "react-redux";
import RegisterationSideImage1 from "./RegisterationSideImage1";
import { errorToast } from "../Toast/ToastMessage";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import axios from "axios";
import { getAuthHeaders, getNoAuthHeaders } from "../../utils/GetHeaders";
import { setTokens } from "../../utils/SetTokens";
import {
  REACT_APP_AUTH_SVC_API_BASE_URL,
  REACT_APP_OTP_API_BASE_URL,
} from "../../utils/UrlConstants";

type VechicleToRentProps = {
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
  onNextButtonKeyDown: () => void;
  onChangeRouteNextButtonClick?: () => void;
  updateRegistrationData: (data: RegistrationData) => void;
  role: string;
  registerData?: RegistrationData;
  onClick?: () => void;
};

const AddOrganizationDetails2: React.FC<
  VechicleToRentProps & PropsFromReduxVechicleToRent
> = ({
  onNextButtonClick,
  onBackButtonClick,
  updateRegistrationData,
  registrationData,
  ...props
}) => {
  const reduxStateRegistrationData = useSelector(
    (state: any) => state.registrationData
  );
  const organizationPhone = useSelector(
    (state: any) => state.organization.phone
  );

  const reduxState = useSelector((state: any) => state);
  const [requiredField, setRequiredField] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>(reduxStateRegistrationData.phone);
  const organizationAddressState = useSelector((state: any) => state.organization.physicalAddress);

  const handleNextClick = async () => {
    console.log(reduxState);
    if (!email || !phone) {
      const emptyField = !email ? "email" : !phone ? "phone" : "";
      setRequiredField(emptyField);
      return;
    } else {
      setRequiredField("");
    }

    if (
      localStorage.getItem("accessToken") &&
      localStorage.getItem("refreshToken")
    ) {
      var url = `${REACT_APP_AUTH_SVC_API_BASE_URL}/me/update`;
      try {
        fetchUser();
        const response: any = await axios.put(
          url,
          {
            email: email,
          },
          {
            headers: getAuthHeaders(),
          }
        );

        console.log(response);
        if (response.data.success == true) {
          localStorage.setItem("currentUserUpdated", "true");
          onNextButtonClick();
        }
      } catch (error) {
        console.error(error, error);
      }
    } else {
      const apiUrl = `${REACT_APP_AUTH_SVC_API_BASE_URL}/register`;

      fetch(apiUrl, {
        method: "POST",
        headers: getNoAuthHeaders(),
        body: JSON.stringify(reduxStateRegistrationData),
      })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            return response.json();
          } else if (response.status === 400) {
            return response.json().then((data) => {
              errorToast(`Bad request: ${data.error}`);
            });
          } else {
            errorToast(`Please enter unique data`);
            console.log(response);
          }
        })
        .then((data) => {
          setTokens(data.accessToken, data.user.refreshTokens[0]);
          handleSendCodeClick();
          onNextButtonClick();
        })
        .catch((error) => {
          console.log(error.body);
        });
    }
  };

  const handleSendCodeClick = async () => {
    try {
      const email = reduxStateRegistrationData.email;
      const apiUrl = `${REACT_APP_OTP_API_BASE_URL}/otp/send/email`;

      const requestBody = {
        email: email,
      };

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: getAuthHeaders(),
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        errorToast("Incorrect One Time Password");
        console.error("Login failed", response);
        return false;
      }

      const data = await response.json();
      if (data.exists === false) {
        errorToast("Email does not exist");
      } else if (data.exists === true) {
        errorToast("Email already exists");
      } else {
        console.log("Success:", data);
      }
    } catch (error) {
      errorToast(`${error}`);
      console.error("Error:", error);
    }
  };

  const handleCompanyEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    const updatedRegistrationData: RegistrationData = {
      ...reduxStateRegistrationData,
      email: newEmail,
      organizations: [
        {
          ...reduxStateRegistrationData.organizations[0],
        },
      ],
    };

    updateRegistrationData(updatedRegistrationData);
  };

  const fetchUser = async () => {
    var url = `${REACT_APP_AUTH_SVC_API_BASE_URL}/me`;
    try {
      const response = await axios.get(url, {
        headers: getAuthHeaders(),
      });
      console.log(response.data.user);
    } catch (error) {
      console.error(error, error);
    }
  };

  const handleBackButtonClick = () => {
    onBackButtonClick();
  };

  return (
    <div className={styles.lookingForRent05}>
      <RegisterationSideImage1 styles={styles} />

      <div className={styles.lookingForRent05Child} />
      <div className={styles.backButtonParent}>
        <div
          className={styles.textButton}
          onClick={handleBackButtonClick}
          style={{ cursor: "pointer" }}
        >
          <img className={styles.icon} alt="" src="/generics/icon5.svg" />
          <div className={styles.reefer}>Back</div>
        </div>
        <div className={styles.stepcircleWithTextParent}>
          <div className={styles.stepcircleWithText}>
            <div className={styles.stepcircle}>
              <img className={styles.icon} alt="" src="/generics/check1.svg" />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Sign up</div>
            </div>
          </div>
          <div className={styles.instanceChild} />
          <div className={styles.stepcircleWithText1}>
            <div className={styles.stepcircle1}>
              <img className={styles.icon} alt="" src="/generics/check.svg" />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Company details</div>
            </div>
          </div>
          <div className={styles.instanceChild} />
          <div className={styles.stepcircleWithText2}>
            <div className={styles.stepcircle2}>
              <div className={styles.dot} />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Verification</div>
            </div>
          </div>
        </div>
        <div className={styles.userNameParent}>
          <div className={styles.userName}>Your organization details</div>
          <div className={styles.inputText3}>
            Please provide your Organization information accurately, it will be
            used in all your communications on the platform.&nbsp;
          </div>
        </div>
        <div className={styles.textButtonGroup}>
          <div className={styles.textButton1}>
            <img className={styles.icon} alt="" src="/generics/icon6.svg" />
            <div className={styles.reefer}>Back&nbsp;</div>
          </div>
          <div className={styles.frameParent}>
            <div className={styles.inputWithLabelParent}>
              <div className={styles.inputWithLabel}>
                <div className={styles.reefer}>Company name&nbsp;</div>
                <div className={styles.basicInput}>
                  <input
                    type="text"
                    className={styles.inputField}
                    value={
                      reduxStateRegistrationData.organizations[0].name ||
                      "Not Defined"
                    }
                    placeholder=" ABC Polymer industries, LLC"
                    style={{ border: "none" }}
                    disabled
                  />
                </div>
              </div>
              <div className={styles.inputWithLabel}>
                <div className={styles.reefer}>Address</div>
                <div className={styles.basicInput}>
                  <input
                    type="text"
                    className={styles.inputField}
                    placeholder={`${organizationAddressState.street}, ${organizationAddressState.state}`}
                    style={{ border: "none" }}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className={styles.inputWithLabelParent}>
              <div className={styles.inputWithLabel}>
                <div className={styles.reefer}>Your e-mail</div>
                <div
                  className={styles.basicInput2}
                  style={
                    requiredField === "email" ? { border: "1px solid red" } : {}
                  }
                >
                  <input
                    type="text"
                    className={styles.inputField}
                    placeholder="Your e-mail"
                    style={{ border: "none" }}
                    value={email}
                    onChange={handleCompanyEmail}
                  />
                </div>
              </div>
              <div className={styles.inputWithLabel}>
                <div className={styles.reefer}>Company phone&nbsp;</div>
                <div
                  className={styles.basicInput2}
                  style={{
                    backgroundColor: "#F1F5F9",
                    border: requiredField === "phone" ? "1px solid red" : "",
                    borderRadius: "5px",
                  }}
                >
                  <PhoneInput
                    disabled
                    className={styles.phoneNumberInput}
                    value={organizationPhone}
                    inputStyle={{
                      backgroundColor: "gray",
                      width: "80%",
                      fontSize: "16px",
                      color: "#64748b",
                    }}
                    disableDialCodeAndPrefix={true}
                  />
                </div>
              </div>
            </div>
            <div className={styles.inputWithLabel4}>
              <div className={styles.inputTextContainer}>
                <div className={styles.reefer}>DOT number</div>
                <div className={styles.frameDiv}>
                  <div className={styles.reefer}>Learn more about your DOT</div>
                  <img
                    className={styles.iconoutlineexternalLink}
                    alt=""
                    src="/generics/iconoutlineexternallink.svg"
                  />
                </div>
              </div>
              <div
                className={styles.basicInput2}
                style={{ background: "#F1F5F9" }}
              >
                <input
                  disabled
                  type="text"
                  className={styles.inputField}
                  placeholder={
                    reduxStateRegistrationData.organizations[0].dotNumber
                  }
                  style={{ border: "none" }}
                />
              </div>
            </div>
          </div>
          <div className={`errorBox ${requiredField ? "active" : ""}`}>
            Please, fill all required fields
          </div>
          <div className={styles.frameChild} />
          <div
            className={`${styles.button1} hoverButtons`}
            onClick={handleNextClick}
          >
            <div className={styles.text} onKeyDown={() => {}} role="button">
              Next
            </div>
          </div>
          <div className={styles.inputTextParent1}>
            <div className={styles.inputText15}>Length</div>
            <div className={styles.tagParent}>
              <div className={styles.tag}>
                <img
                  className={styles.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer}>Small</div>
              </div>
              <div className={styles.tag}>
                <img
                  className={styles.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer}>Large</div>
              </div>
            </div>
          </div>
          <div className={styles.inputTextParent1}>
            <div className={styles.inputText15}>Lift Gate</div>
            <div className={styles.tagParent}>
              <div className={styles.tag}>
                <img
                  className={styles.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer}>Rail Gate</div>
              </div>
              <div className={styles.tag}>
                <img
                  className={styles.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer}>Tuck Under</div>
              </div>
              <div className={styles.tag}>
                <img
                  className={styles.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer}>No Lift Gate</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOrganizationDetails2;
