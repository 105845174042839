import { FunctionComponent } from "react";
import styles from "./css/UserAdministration.module.css";
import AdminNavBarLeadingContent from "./AdminNavBarLeadingContent";
import { useGlobalContext } from "../../components/Global/GlobalState";
import classNames from "classnames";
interface ICollapsedsidebar {
  isCollapsed?: boolean;
}

const AdminSidebar: FunctionComponent<ICollapsedsidebar> = ({
  isCollapsed,
}) => {
  const { sideBarToggle } = useGlobalContext();
  return (
    <div
      className={classNames(styles.simpleWithIconsBadgesAnd, {
        [styles.hidden]: sideBarToggle,
      })}
    >
      <AdminNavBarLeadingContent isCollapsed={isCollapsed} />
    </div>
  );
};

export default AdminSidebar;
