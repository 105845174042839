export const manuallyImportedTruckData = [
  {
    imgSrc: "./truck_types/Beverage_Trucks.svg",
    headline: "beverage trucks mounted un-mounted",
    short: "Beverage Trucks",
  },
  {
    imgSrc: "./truck_types/Box_Trucks.svg",
    headline: "box trucks dry Van trucks / straight trucks moving cutaway-cube",
    short: "Box Trucks",
  },
  {
    imgSrc: "./truck_types/Car_Carrier_Trailers_Semi-Trailers.svg",
    headline: "car carrier trailers semi-trailers -enclosed -open",
    short: "Car Carrier",
  },
  {
    imgSrc: "./truck_types/Car_Hauler_Trucks.svg",
    headline: "car hauler trucks",
    short: "Car Hauler",
  },
  {
    imgSrc: "./truck_types/Day_Cab_Trucks.svg",
    headline: "day cab trucks",
    short: "Day Cab",
  },
  {
    imgSrc: "./truck_types/Drop_Deck_Semi-Trailer.svg",
    headline: "drop deck semi-trailer",
    short: "Drop Deck",
  },
  {
    imgSrc: "./truck_types/Dry_Van_Semi-Trailer.svg",
    headline: "dry van semi-trailer",
    short: "Dry Van",
  },
  {
    imgSrc: "./truck_types/Expeditor_Trucks.svg",
    headline: "expeditor trucks",
    short: "Expeditor",
  },
  {
    imgSrc: "./truck_types/Flatbed_Semi-Trailer.svg",
    headline: "flatbed semi-trailer -48ft -53ft",
    short: "Flatbed Trailer",
  },
  {
    imgSrc: "./truck_types/Flatbed_Trucks.svg",
    headline: "flatbed trucks",
    short: "Flatbed Trucks",
  },
  {
    imgSrc: "./truck_types/Glider_Kit_Trucks.svg",
    headline: "glider kit trucks",
    short: "Glider Kit",
  },
  {
    imgSrc: "./truck_types/Hot_Shot_Trucks.svg",
    headline: "hot shot trucks mounted un-mounted",
    short: "Hot Shot",
  },
  {
    imgSrc: "./truck_types/Lowboy_Semi-Trailer.svg",
    headline: "lowboy semi-trailer",
    short: "Lowboy",
  },
  {
    imgSrc: "./truck_types/Reefer_Refrigerated_Trucks.svg",
    headline: "reefer / refrigerated trucks",
    short: "Reefer",
  },
  {
    imgSrc: "./truck_types/Reefer_Semi-Trailer.svg",
    headline: "reefer semi-trailer",
    short: "Reefer semi-trailer",
  },
  {
    imgSrc: "./truck_types/Sleeper_Trucks.svg",
    headline: "sleeper trucks",
    short: "Sleeper",
  },
];

export const manuallyImportedTruckDataOnly = [
  {
    imgSrc: "./truck_types/Sleeper_Trucks.svg",
    headline: "sleeper trucks",
  },
  {
    imgSrc: "./truck_types/Day_Cab_Trucks.svg",
    headline: "day cab trucks",
  },
  {
    imgSrc: "./truck_types/Car_Hauler_Trucks.svg",
    headline: "car hauler trucks",
  },
  {
    imgSrc: "./truck_types/Glider_Kit_Trucks.svg",
    headline: "glider kit trucks",
  },
  {
    imgSrc: "./truck_types/Flatbed_Trucks.svg",
    headline: "flatbed trucks",
  },
  {
    imgSrc: "./truck_types/Hot_Shot_Trucks.svg",
    headline: "hot shot trucks mounted un-mounted",
  },
  {
    imgSrc: "./truck_types/Box_Trucks.svg",
    headline: "box trucks dry Van trucks / straight trucks moving cutaway-cube",
  },
  {
    imgSrc: "./truck_types/Reefer_Refrigerated_Trucks.svg",
    headline: "reefer / refrigerated trucks",
  },
  {
    imgSrc: "./truck_types/Beverage_Trucks.svg",
    headline: "beverage trucks mounted un-mounted",
  },
  {
    imgSrc: "./truck_types/Expeditor_Trucks.svg",
    headline: "expeditor trucks",
  },
];

export const manuallyImportedTrailerDataOnly = [
  {
    imgSrc: "./truck_types/Car_Carrier_Trailers_Semi-Trailers.svg",
    headline: "car carrier trailers semi-trailers -enclosed -open",
  },
  {
    imgSrc: "./truck_types/Drop_Deck_Semi-Trailer.svg",
    headline: "drop deck semi-trailer",
  },
  {
    imgSrc: "./truck_types/Dry_Van_Semi-Trailer.svg",
    headline: "dry van semi-trailer",
  },
  {
    imgSrc: "./truck_types/Flatbed_Semi-Trailer.svg",
    headline: "flatbed semi-trailer -48ft -53ft",
  },
  {
    imgSrc: "./truck_types/Lowboy_Semi-Trailer.svg",
    headline: "lowboy semi-trailer",
  },

  {
    imgSrc: "./truck_types/Reefer_Semi-Trailer.svg",
    headline: "reefer semi-trailer",
  },
];
