import { FunctionComponent, useState } from "react";
import styles from "./Profile.module.css";
import Modal from 'react-bootstrap/Modal';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

interface PasswordModalBodyProps {
  setLgShow: (value: boolean) => void;
  handlePasswordChange: (value: any) => void;
  lgShow: boolean;
  formPasswordData : 
    {
      oldPassword: string,
      newPassword: string,
      confirmPassword: string,
    }
}

const PasswordModalBody: FunctionComponent<PasswordModalBodyProps> = ({
  formPasswordData,
  handlePasswordChange
}) => {
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  return (
    <>
      <Modal.Body>
        <div className={`${styles.profileForm} ${styles.passwordForm}`}>
          <div className={`${styles.formGroup} row`}>
            <label className={`${styles.passwordLabel}`} htmlFor="oldPassword">Old password</label>
            <div className="col-6" style={{ position: 'relative' }}>
              <input
                className={`${styles.passwordField} ${styles.inputField}`}
                type={showOldPassword ? 'text' : 'password'}
                id="oldPassword"
                value={formPasswordData.oldPassword}
                onChange={handlePasswordChange}
              />
              <span
                onClick={() => setShowOldPassword(!showOldPassword)}
                style={{
                  position: 'absolute',
                  right: '20px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer'
                }}
              >
                {showOldPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
              </span>
            </div>
          </div>
          <div className={`${styles.formGroup} row`}>
            <label className={`${styles.passwordLabel}`} htmlFor="newPassword">New password</label>
            <div className="col-6" style={{ position: 'relative' }}>
              <input
                className={`${styles.passwordField} ${styles.inputField}`}
                type={showNewPassword ? 'text' : 'password'}
                id="newPassword"
                value={formPasswordData.newPassword}
                onChange={handlePasswordChange}
              />
              <span
                onClick={() => setShowNewPassword(!showNewPassword)}
                style={{
                  position: 'absolute',
                  right: '20px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer'
                }}
              >
                {showNewPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
              </span>
            </div>
          </div>
          <div className={`${styles.formGroup} row`}>
            <label className={`${styles.passwordLabel}`} htmlFor="confirmPassword">Confirm password</label>
            <div className="col-6" style={{ position: 'relative' }}>
              <input
                className={`${styles.passwordField} ${styles.inputField}`}
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                value={formPasswordData.confirmPassword}
                onChange={handlePasswordChange}
              />
              <span
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                style={{
                  position: 'absolute',
                  right: '20px',
                  top: '25%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer'
                }}
              >
                {showConfirmPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
              </span>
                <p className={styles.minChars}>Minimum 6 characters</p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  )
}

export default PasswordModalBody;
