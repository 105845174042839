import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Carousel from "react-bootstrap/Carousel";

const createCustomIcon = (truckCount: number, styles: any) => {
  return L.divIcon({
    className: `${styles.customMarker}`,
    html: `<div style="background-color: white; border-radius: 16px; width: 75px; height: 35px; display: flex; justify-content: center; align-items: center; color: black; font-weight: bold; box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);">
    ${truckCount > 1 ? `+${truckCount}` : `$${truckCount}`}
  </div>`,
    iconSize: [50, 50],
    iconAnchor: [25, 25],
    popupAnchor: [0, -20],
  });
};

interface Truck {
  _id: string;
  city: string;
  state: string;
  zipCode: string;
  rentPerDay: number;
  year: number;
  manufacturer: string;
  model: string;
  images: { threeQuarterView: string };
}

interface MapComponentProps {
  trucks?: Truck[];
  styles?: any;
  id?: any;
  width?: string;
  singleTruck?: any;
}

const MapComponent: React.FC<MapComponentProps> = ({
  trucks,
  styles,
  id,
  width,
  singleTruck,
}) => {
  const position: [number, number] = [41.85532, -87.803921];
  const [truckLocations, setTruckLocations] = useState<
    {
      locationKey: string;
      trucks: Truck[];
      position: [number, number];
    }[]
  >([]);
  const [singleTruckPosition, setSingleTruckPosition] = useState<
    [number, number] | null
  >(null);

  const hoveredTruckId = useSelector(
    (state: any) => state?.mapActions?.hoveredTruckId
  );

  const markerRefs = useRef<Map<string, any>>(new Map());

  const fetchCoordinates = async (
    query: string
  ): Promise<[number, number] | null> => {
    const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
      query
    )}&format=json&addressdetails=1`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.length > 0) {
        const { lat, lon } = data[0];
        return [parseFloat(lat), parseFloat(lon)];
      }
    } catch (error) {
      console.error("Geocoding failed:", error);
    }
    return null;
  };

  useEffect(() => {
    const fetchAllCoordinates = async () => {
      const locationMap = new Map<string, Truck[]>();

      // Group trucks by location
      trucks?.forEach((truck) => {
        const locationKey = `${truck.city}-${truck.state}-${truck.zipCode}`;
        if (!locationMap.has(locationKey)) {
          locationMap.set(locationKey, []);
        }
        locationMap.get(locationKey)?.push(truck);
      });

      const locations = await Promise.all(
        Array.from(locationMap.entries()).map(
          async ([locationKey, truckList]) => {
            const query = `${truckList[0].city}, ${truckList[0].state}, ${truckList[0].zipCode}`;
            const position = await fetchCoordinates(query);
            return position
              ? { locationKey, trucks: truckList, position }
              : null;
          }
        )
      );

      setTruckLocations(
        locations.filter((location) => location !== null) as any
      );
    };

    fetchAllCoordinates();
  }, [trucks]);

  // New useEffect to handle singleTruck initialization and location fetch
  useEffect(() => {
    const fetchSingleTruckLocation = async () => {
      if (singleTruck) {
        const query = `${singleTruck.city}, ${singleTruck.state}, ${singleTruck.zipCode}`;
        const position = await fetchCoordinates(query);
        if (position) {
          setSingleTruckPosition(position);
        }
      }
    };

    fetchSingleTruckLocation();
  }, [singleTruck]);

  const popupTimeouts = useRef(new Map());

  const handleMouseOver = (e: any, truckId: string) => {
    if (popupTimeouts.current.has(truckId)) {
      clearTimeout(popupTimeouts.current.get(truckId));
    }

    // Open the popup
    e.target.openPopup();

    // Add event listener to popup element
    const popupElement = e.target._popup._container;
    popupElement.addEventListener("mouseout", (event: any) => {
      if (!popupElement.contains(event.relatedTarget)) {
        e.target.closePopup();
      }
    });

    // Set the timer to automatically close the popup after 5 seconds
    // const timeout = setTimeout(() => {
    //   e.target.closePopup();
    // }, 5000);

    // Store the timeout so it can be cleared later if necessary
    // popupTimeouts.current.set(truckId, timeout);
  };

  const handleMouseOut = (e: any, truckId: string) => {
    // const popupElement = e.target._popup._container;
    // if (
    //   !popupElement.contains(e.relatedTarget) &&
    //   !popupElement.contains(e.target)
    // ) {
    //   e.target.closePopup();
    // }
  };

  useEffect(() => {
    if (hoveredTruckId && markerRefs.current.has(hoveredTruckId)) {
      const marker = markerRefs.current.get(hoveredTruckId);
      const currentIcon = marker.getIcon();

      const updatedIcon = L.divIcon({
        ...currentIcon.options, // Retain other options
        className: `custom-marker ${styles.hoveredMarker}`, // Add a specific class for hovered markers
        html: currentIcon.options.html, // Keep the same HTML content
      });

      marker.setIcon(updatedIcon); // Update the icon with the new styles
    }
  }, [hoveredTruckId]);

  return (
    <MapContainer
      center={position}
      zoom={9}
      style={{ height: "99vh", width: width }}
    >
      <TileLayer
        url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      />
      {truckLocations.map(({ locationKey, trucks, position }) => (
        <Marker
          icon={createCustomIcon(trucks.length, styles)}
          key={locationKey}
          position={position}
          ref={(ref) => {
            if (ref) {
              trucks.forEach((truck) => markerRefs.current.set(truck._id, ref));
            }
          }}
          eventHandlers={{
            mouseover: (e) => handleMouseOver(e, locationKey),
            mouseout: (e) => handleMouseOut(e, locationKey),
            click: () => {
              console.log(`Clicked on location: ${locationKey}`);
              // Handle additional click actions
            },
          }}
        >
          <Popup>
            <Carousel
              className={`${styles.vecicleCard2} ${styles.vecicleCard2_popup}`}
              id="truck_container"
              interval={null}
            >
              {Object.entries(trucks[0].images)
                .filter(([key, value]) => value)
                .map(([key, value]) => (
                  <Carousel.Item
                    key={`${trucks[0]._id}-${key}`}
                    onClick={() =>
                      window.open(`#/truck/${trucks[0]._id}`, "_blank")
                    }
                  >
                    <div
                      className={`${
                        styles.frameParent3 || "frame-24031@3x.png"
                      } hoverButtons ${styles.frameParent3Popup}`}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={`${value || "frame-24031@3x.png"}`}
                        className={`${styles.frameParent3} hoverButtons`}
                        style={{
                          width: "100%",
                          height: "19vh",
                          objectFit: "cover",
                        }}
                        alt={`Truck ${key}`}
                      />
                    </div>
                  </Carousel.Item>
                ))}
            </Carousel>
            <div
              style={{
                textAlign: "left",
                padding: "0 15px 10px 15px",
                fontSize: "18px",
                fontWeight: 600,
                marginTop: "10px",
              }}
            >
              <div>
                <div style={{ textTransform: "capitalize" }}>
                  {trucks[0].year} {trucks[0].manufacturer} {trucks[0].model}
                </div>
              </div>
              ${trucks[0].rentPerDay}/Day
            </div>
          </Popup>
        </Marker>
      ))}
      {singleTruckPosition && (
        <Marker
          icon={createCustomIcon(1, styles)}
          position={singleTruckPosition}
        >
          <Popup>
            {singleTruck.city}, {singleTruck.state}, {singleTruck.zipCode}
            <img
              style={{ height: "50px", width: "50px" }}
              src={singleTruck?.images.threeQuarterView}
              alt={`Truck ${singleTruck?._id}`}
            />
          </Popup>
        </Marker>
      )}
    </MapContainer>
  );
};

export default MapComponent;
