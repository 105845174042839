import { FunctionComponent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./css/UserAdministration.module.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setOrganization } from "../../store/actions/organizationAction";
import { REACT_APP_ORG_API_BASE_URL } from "../../utils/UrlConstants";
import classNames from "classnames";
import { useGlobalContext } from "../../components/Global/GlobalState";

interface ICollapsedsidebar {
  isCollapsed?: boolean;
}

const AdminNavBarLeadingContent: FunctionComponent<ICollapsedsidebar> = ({
  isCollapsed,
}) => {
  const dispatch = useDispatch();
  const reduxState = useSelector((state: any) => state);
  const currentUser = useSelector((state: any) => state.currentUser);
  const userOrganization: string = reduxState.currentUser.organizations[0];
  const { sideBarToggle, setSideBarToggle } = useGlobalContext();
  const [ currentUrl, setCurrentUrl ] = useState<string>(window.location.href)
  
  useEffect(() => {
    const fetchOrganization = async (id: string) => {
      const url = `${REACT_APP_ORG_API_BASE_URL}/organization/${id}`;
      try {
        const response = await axios.get(url);
        if (response.status === 200) {
          dispatch(setOrganization(response.data.organization));
        }
      } catch (error) {
        console.error("Error fetching organization:", error);
      }
    };

    if (userOrganization) {
      fetchOrganization(userOrganization);
    }
  }, [userOrganization, dispatch]);

  return (
    <>
      <span 
        className={classNames(styles.toggleAble, {
          [styles.hidden]: sideBarToggle,
        })}
      >
        <div>
          <div className={styles.leadingContent}>
            {!isCollapsed && (
              <div className={styles.header}>
                <Link className={styles.sidebarLinks} to={currentUser && currentUser.role == 'renter' ? '/admin/truck_management' : '/main'}>
                  <img
                    className={styles.theLogoMakerBuiltFor1000}
                    alt=""
                    src="/logos/Truck4 logo-1.png"
                    onClick={() => {
                      window.location.href = "/#/main";
                    }}
                  />
                </Link>
                <div className={styles.iconoutlinearrowHide} onClick={() => setSideBarToggle(!sideBarToggle) } >
                  <img className={styles.icon} alt="" src="/admin/icon.svg" />
                  <div className={styles.iconoutlinearrowHideChild} />
                </div>
              </div>
            )}
  
            <div className={styles.navigation}>
              <Link
                className={styles.sidebarLinks}
                to="/admin"
                style={{ width: "100%" }}
              >
                <div className={`${styles.verticalNavigationitem1} ${currentUrl.includes('/admin') && !currentUrl.includes('admin/') ? 'bg-gray' : ''}`}>
                  <div className={styles.content}>
                    <img
                      className={styles.homeIcon}
                      alt=""
                      src="/admin/home.svg"
                    />
                    <div className={styles.text11}>Main Page</div>
                  </div>
                </div>
              </Link>
              <Link
                className={styles.sidebarLinks}
                to="/admin/ads/reservations"
                style={{ width: "100%" }}
              >
                <div className={`${styles.verticalNavigationitem1} ${currentUrl.includes('/admin/ads/reservations') ? 'bg-gray' : ''}`}>
                  <div className={`${styles.content2}`}>
                    <img
                      className={styles.iconoutlinecurrencyDollar}
                      alt=""
                      src="/admin/iconoutlinecurrencydollar.svg"
                    />
                    <div className={styles.text11}>Bookings</div>
                  </div>
                </div>
              </Link>
              <div className={styles.navigationChild} />
              <Link
                className={styles.sidebarLinks}
                to="/admin/truck_management"
                style={{ width: "100%" }}
              >
                <div className={`${styles.verticalNavigationitem1} ${currentUrl.includes('/admin/truck_management') ? 'bg-gray' : ''}`}>
                  <div className={styles.content10}>
                    <LocalShippingIcon />
                    <div className={styles.text11}>Asset Management</div>
                  </div>
                </div>
              </Link>
  
              <div className={styles.verticalNavigationitem1} >
                <Accordion defaultExpanded={ currentUrl.includes('settings') || currentUrl.includes('organization') || currentUrl.includes('user_administration') ? true : false } style={{ boxShadow: "unset", width: "100%" }}>
                  <AccordionSummary
                    style={{ padding: "unset" }}
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "14%",
                      }}
                    >
                      <div className={styles.content8}>
                        <img
                          className={styles.iconoutlinecog}
                          alt=""
                          src="/admin/iconoutlinecog.svg"
                        />
                      </div>
                      <div className={styles.text11}>Settings</div>
                    </span>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '0px 0px 0px 28px' }}>
                    <Link
                      className={styles.sidebarLinks}
                      to={`/admin/settings/profile`}
                    >
                      <div className={`${currentUrl.includes('admin/settings/profile') ? 'bg-gray' : ''} ${styles.settingSubTab} mt-1`}>
                        <div className={`${styles.content10}`}>
                          <div className={styles.text11}>Profile</div>
                        </div>
                      </div>
                    </Link>
                    <Link
                      className={styles.sidebarLinks}
                      to={`/organization/${userOrganization}`}
                    >
                      <div className={`${currentUrl.includes('/organization') ? 'bg-gray' : ''} ${styles.settingSubTab} mt-1`}>
                        <div className={styles.content10}>
                          <div className={styles.text11}>Company</div>
                        </div>
                      </div>
                    </Link>
                    <Link
                      className={styles.sidebarLinks}
                      to="/admin/user_administration"
                    >
                      <div className={`${currentUrl.includes('admin/user_administration') ? 'bg-gray' : ''} ${styles.settingSubTab} mt-1`}>
                        <div className={styles.content10}>
                          <div className={styles.text11}>User Administration</div>
                        </div>
                      </div>
                    </Link>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.verticalNavigationitem1}>
          <div className={styles.content12}>
            <img
              className={styles.iconoutlinelightningBolt}
              alt=""
              src="/admin/iconoutlinelightningbolt.svg"
            />
            <div className={styles.text11}>Subscription</div>
          </div>
          <div className={`${styles.tag} hoverButtons`}>
            <div className={styles.text13}>Free plan</div>
          </div>
        </div>
        <div className={styles.footer} onClick={undefined}>
          <div className={styles.topBorder} />
          <Link
            className={styles.sidebarLinks}
            to={`/organization/${userOrganization}`}
          >
            <div className={styles.content13}>
              <div className={styles.avatarWithText}>
                <img
                  className={styles.avatarIcon}
                  alt=""
                  src="/admin/avatar@2x.png"
                />
                <div className={styles.text14} style={{ textAlign: "left" }}>
                  <div className={styles.title}>
                    <p>
                      {reduxState.currentUser.firstName +
                        " " +
                        reduxState.currentUser.lastName}
                    </p>
                    <p>{reduxState.organization.name}</p>
                    <span
                      style={{
                        backgroundColor: "lightblue",
                        padding: "5%",
                        borderRadius: "32px",
                      }}
                    >
                      {reduxState.currentUser.role.toUpperCase()}
                    </span>
                  </div>
                  <div className={styles.supportingText}>View profile</div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </span>
      {
        sideBarToggle && (
          <span className={`${styles.openSidebarToggle} `} onClick={() => setSideBarToggle(!sideBarToggle) } >
            <svg fill="#808080" height="30px" width="30px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <g>
                  <g>
                    <path d="M256,0C114.859,0,0,114.837,0,256c0,141.141,114.859,256,256,256c141.163,0,256-114.859,256-256 C512,114.837,397.163,0,256,0z M403.691,264.149c-1.088,2.603-2.645,4.971-4.608,6.933l-85.333,85.333 c-4.16,4.16-9.621,6.251-15.083,6.251c-5.461,0-10.901-2.091-15.083-6.251c-8.32-8.341-8.32-21.845,0-30.165l48.917-48.917H128 c-11.776,0-21.333-9.557-21.333-21.333c0-11.797,9.557-21.333,21.333-21.333h204.501l-48.917-48.917 c-8.32-8.341-8.32-21.845,0-30.165c8.341-8.341,21.845-8.341,30.165,0l85.333,85.312c1.963,1.963,3.52,4.331,4.608,6.955 C405.845,253.056,405.845,258.923,403.691,264.149z"></path>
                  </g>
                </g>
              </g>
            </svg>
          </span>
        )
      }
    </>
  );
};

export default AdminNavBarLeadingContent;
