import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect, ConnectedProps, useSelector } from "react-redux";
import styles from "./css/AddTruckApplyToRent.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { TruckRegistrationData, RootStateTruckAdd } from "../../store/types";
import { updateTruckRegistrationData } from "../../store/actions/registrationActions";
import styled from "styled-components";
import { errorToast } from "../../components/Toast/ToastMessage";
import { plateStates as options } from "./selectValues";

export type PropsFromReduxAddTruckToRent = ConnectedProps<
  typeof connectorAddTruckToRent
>;

export type AddTruckApplyToRentProps = {
  handleUpdateTruckRegistrationData: (data: TruckRegistrationData) => void;
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
  setPlateNumber: (value: string) => void;
  setInputValue: (value: string) => void;
  setPlateState: (value: string) => void;
  setVINNumber: (value: string) => void;
  setCity: (value: string) => void;
  setState: (value: string) => void;
  setZipCode: (value: string) => void;
  truckRegData: TruckRegistrationData;
  plateNumber: string;
  inputValue: string;
  plateState: string;
  VINNumber: string;
  idToUpdate?: TruckRegistrationData;
  city: string;
  state: string;
  zipCode: string;
  currentStep: number;
} & PropsFromReduxAddTruckToRent;

const mapDispatchToProps = {
  updateTruckRegistrationData,
};

const mapStateToProps = (state: RootStateTruckAdd) => ({
  truckAddRegistrationData: state.truckRegistrationData,
});

const connectorAddTruckToRent = connect(mapStateToProps, mapDispatchToProps);

const AddTruckApplyToRent: React.FC<AddTruckApplyToRentProps> = ({
  onNextButtonClick,
  updateTruckRegistrationData,
  onBackButtonClick,
  setPlateNumber,
  setInputValue,
  setPlateState,
  setVINNumber,
  setCity,
  setState,
  setZipCode,
  idToUpdate,
  truckAddRegistrationData,
  plateNumber,
  inputValue,
  VINNumber,
  city,
  state,
  zipCode,
  currentStep,
}) => {
  const handleNextClick = () => {
    if (validateForm()) {
      onNextButtonClick();
    } else {
      errorToast("Please fill all fields");
    }
  };


  const [isDropdownOpen1, setIsDropdownOpen1] = useState<boolean>(false);
  const [requiredField, setRequiredField] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<string>("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    setIsDropdownOpen1(true);
  };

  const handleOptionSelect = (option: string) => {
    setSelectedOption(option);
    setIsDropdownOpen1(false);
    setInputValue(option);
    handleStateChange(option);
  };

  const navigate = useNavigate();

  const handlePlateNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPlateNumber = e.target.value;
    setPlateNumber(newPlateNumber);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      plateNumber: newPlateNumber,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCity = e.target.value;
    setCity(newCity);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      city: newCity,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleLocationStateChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newState = e.target.value;
    setState(newState);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      state: newState,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newZipCode = e.target.value;
    setZipCode(newZipCode);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      zipCode: newZipCode,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleStateChange = (state: string) => {
    const newState = state;
    setPlateState(newState);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      plateState: newState,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleVIN = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVIN = e.target.value;
    setVINNumber(newVIN);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      VIN: newVIN,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen1(!isDropdownOpen1);
  };

  const onInputTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(inputValue.toLowerCase())
  );

  const onInputWithLabelClick = useCallback(() => {}, []);

  const validateForm = () => {
    if (
      (!plateNumber ||
        !inputValue ||
        !VINNumber ||
        !city ||
        !state ||
        !zipCode) &&
      !idToUpdate
    ) {
      const emptyField = !plateNumber
        ? "plateNumber"
        : !inputValue
        ? "inputValue"
        : !VINNumber
        ? "VINNumber"
        : !city
        ? "city"
        : !state
        ? "state"
        : !zipCode
        ? "zipCode"
        : "";
      setRequiredField(emptyField);
      return false;
    } else {
      setRequiredField("");
      return true;
    }
  };

  return (
    <form className={styles.registerATruckOrEdit1P}>
      <div className={styles.registerATruckOrEdit1PChild} />
      <div className={`${styles.component35Wrapper} ${currentStep == 8 && 'mt-3'} `}>
        {
          currentStep != 8 ? (
            <div className={styles.component35}>
              <div className={styles.textFrameWrapper}>
                <div className={styles.textFrame}>
                  <div className={styles.textButton} onClick={onBackButtonClick}>
                    <img
                      className={styles.icon}
                      loading="eager"
                      alt=""
                      src="/generics/icon.svg"
                    />
                    <div className={styles.text1}>Back</div>
                  </div>
                  <div className={styles.inputText2} onClick={onInputTextClick}>
                    1 of 6
                  </div>
                </div>
              </div>
              <div className={styles.userNameParent}>
                <h2 className={styles.userName}>Apply to rent vehicle</h2>
                <div className={styles.inputText3}>
                  Sign up with your work Google account or use the form.
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.component35}>
              <div className={styles.userNameParent}>
                <h2 className={styles.userName}>Final Preview</h2>
                <div className={styles.inputText3}>
                  please recheck the truck data you've added
                </div>
              </div>
            </div>
          )
        }
      </div>
      <div className={styles.registrationFrameWrapper}>
        <div className={styles.registrationFrame}>
          <div className={styles.textButton1}>
            <img className={styles.icon1} alt="" src="/generics/icon-1.svg" />
            <div className={styles.text2}>&nbsp;Back&nbsp;</div>
          </div>
          <div className={styles.inputField}>
            <div className={styles.inputWithLabelParent}>
              <div
                className={styles.inputWithLabel}
                onClick={onInputWithLabelClick}
              >
                <div className={styles.inputText4}>Add Plate Number*</div>
                <div
                  className={styles.basicInput}
                  style={{
                    border:
                      requiredField == "plateNumber" ? "1px solid red" : "",
                  }}
                >
                  <input
                    className={styles.inputText5}
                    placeholder="Type Plate Number"
                    type="text"
                    maxLength={25}
                    value={plateNumber || idToUpdate?.plateNumber}
                    onChange={handlePlateNumberChange}
                  />
                </div>
              </div>
              <div className={styles.inputWithLabel1}>
                <div className={styles.inputText6}>Plate State*</div>

                <div
                  className={styles.basicInput}
                  style={{
                    position: "relative",
                    border:
                      requiredField == "inputValue" ? "1px solid red" : "",
                  }}
                  onChange={handleInputChange}
                  onClick={toggleDropdown}
                >
                  <input
                    className={styles.inputText5}
                    placeholder="Type Plate Number"
                    type="text"
                    value={inputValue || idToUpdate?.plateState}
                    onChange={handleInputChange}
                  />
                  <FontAwesomeIcon key={"1"} icon={faChevronDown} />
                  {isDropdownOpen1 && (
                    <Container>
                      {filteredOptions.map((option, index) => (
                        <Item
                          key={index}
                          className={`hoverDropdown ${
                            inputValue.toLocaleLowerCase() ==
                            option.toLocaleLowerCase()
                              ? "text-theme"
                              : ""
                          } `}
                          onClick={() => handleOptionSelect(option)}
                        >
                          {option}
                        </Item>
                      ))}
                    </Container>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.yourNumberWill}>
              Your number will not be displayed in the public domain
            </div>
          </div>
          <div className={styles.inputWithLabel2}>
            <div className={styles.inputText8}>Add VIN Number*</div>
            <div
              className={styles.basicInput1}
              style={{
                border: requiredField == "VINNumber" ? "1px solid red" : "",
              }}
            >
              <input
                className={styles.inputText9}
                placeholder="Type VIN Number"
                type="text"
                maxLength={35}
                value={VINNumber || idToUpdate?.VIN}
                onChange={handleVIN}
              />
            </div>
          </div>
          <hr className="w-100" />
          <div className="d-flex w-100" style={{ flexDirection: "column" }}>
            <p className={styles.inputText4} style={{ fontSize: "20px" }}>
              Truck Location
            </p>
            <span className={styles.inputText3}>
              Please, provide truck's current location
            </span>
          </div>
          <div className={styles.inputWithLabelParent}>
            <div className={styles.inputWithLabel}>
              <div className={styles.inputText4}>City*</div>
              <div
                className={styles.basicInput}
                style={{
                  border: requiredField == "city" ? "1px solid red" : "",
                }}
              >
                <input
                  className={styles.inputText5}
                  placeholder="Enter City"
                  type="text"
                  maxLength={25}
                  value={city || idToUpdate?.city}
                  onChange={handleCityChange}
                />
              </div>
            </div>
            <div className={styles.inputWithLabel1}>
              <div className={styles.inputText6}>State*</div>

              <div
                className={styles.basicInput}
                style={{
                  border: requiredField == "state" ? "1px solid red" : "",
                }}
              >
                <input
                  className={styles.inputText5}
                  placeholder="Enter state"
                  type="text"
                  maxLength={25}
                  value={state || idToUpdate?.state}
                  onChange={handleLocationStateChange}
                />
              </div>
            </div>
          </div>
          <div className={styles.inputWithLabelParent}>
            <div className={styles.inputWithLabel}>
              <div className={styles.inputText4}>Zip Code*</div>
              <div
                className={styles.basicInput}
                style={{
                  border: requiredField == "zipCode" ? "1px solid red" : "",
                }}
              >
                <input
                  className={styles.inputText5}
                  placeholder="Enter Zip Code"
                  type="number"
                  maxLength={25}
                  value={zipCode || idToUpdate?.zipCode}
                  onChange={handleZipCodeChange}
                />
              </div>
            </div>
          </div>
          <div className={styles.inputTextGroup}>
            <div className={styles.inputText10}>Lenght</div>
            <div className={styles.tagParent}>
              <div className={styles.tag}>
                <input
                  className={styles.earnRevenueFromYourIdleFl}
                  type="checkbox"
                />
                <div className={styles.reefer}>Small</div>
              </div>
              <div className={styles.tag1}>
                <input
                  className={styles.earnRevenueFromYourIdleFl1}
                  type="checkbox"
                />
                <div className={styles.reefer1}>Large</div>
              </div>
            </div>
          </div>
          <div className={styles.inputTextContainer}>
            <div className={styles.inputText11}>Lift Gate</div>
            <div className={styles.tagGroup}>
              <div className={styles.tag2}>
                <input
                  className={styles.earnRevenueFromYourIdleFl2}
                  type="checkbox"
                />
                <div className={styles.reefer2}>Rail Gate</div>
              </div>
              <div className={styles.tag3}>
                <input
                  className={styles.earnRevenueFromYourIdleFl3}
                  type="checkbox"
                />
                <div className={styles.reefer3}>Tuck Under</div>
              </div>
              <div className={styles.tag4}>
                <img
                  className={styles.earnRevenueFromYourIdleFl4}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1-4@2x.png"
                />
                <div className={styles.reefer4}>No Lift Gate</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      { 
        currentStep != 8 && (
          <div className={styles.registerATruckOrEdit1PInner}>
          <div className={styles.buttonParent}>
            <div className={styles.button4}>
              <div className={styles.textButton2}>Save as a draft</div>
            </div>
            <div className={styles.buttonText}>
              <div className={styles.button5}>
                <div className={styles.buttonHide}>Back</div>
              </div>
              <div className={styles.button6} onClick={handleNextClick}>
                <div className={styles.text3}>Next</div>
              </div>
            </div>
          </div>
        </div>
        )
      }
    </form>
  );
};

export default connectorAddTruckToRent(AddTruckApplyToRent);

const StyledButton = styled.button`
  position: absolute;
  top: 20%;
  border: 0;
  right: 5%;
  background-color: transparent;
`;

const Container = styled.div`
  max-height: 24vh;
  overflow: auto;
  position: absolute;
  top: 105%;
  width: 100%;
  left: 0;
  border-radius: 8px;
  box-shadow: 0px 10px 22px 0px rgba(45, 77, 108, 0.15);
  background-color: var(--basic-white, #fff);
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  color: var(--neutral-400, #94a3b8);
  white-space: nowrap;
  align-items: start;
  border-radius: 8px;
  background-color: var(--basic-white, #fff);
  margin-top: 4px;
  width: 100%;
  justify-content: center;
  padding: 8px 60px 8px 16px;
  font: 400 14px/20px Inter, sans-serif;

  @media (max-width: 991px) {
    white-space: initial;
    padding-right: 20px;
  }
`;

const Item = styled.div`
  text-align: left;
  color: var(--neutral-900, #0f172a);
  white-space: nowrap;
  align-items: start;
  border-radius: 8px;
  background-color: var(--basic-white, #fff);
  width: 100%;
  justify-content: center;
  padding: 8px 60px 8px 16px;
  font: 400 14px/20px Inter, sans-serif;

  @media (max-width: 991px) {
    white-space: initial;
    padding-right: 20px;
  }
`;
