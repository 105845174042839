import React, { useState } from "react";
import styles from "./ImageViewer.module.css";

interface ImageViewerProps {
  images: string[];
  setImageViewer: (value: boolean) => void;
}

const ImageViewer: React.FC<ImageViewerProps> = ({
  images,
  setImageViewer,
}) => {
  const validImages = images.filter(
    (image) => image != null && image.trim() !== ""
  );

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? validImages.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === validImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const currentImage = validImages[currentIndex];

  return (
    <>
      <div className={styles.imageViewer}>
        <button
          className={`${styles.arrow} ${styles.leftArrow}`}
          onClick={goToPrevious}
        >
          &#8592;
        </button>

        {currentImage && (
          <img
            src={currentImage}
            alt={`Slide ${currentIndex}`}
            className={styles.image}
          />
        )}

        <button
          className={`${styles.arrow} ${styles.rightArrow}`}
          onClick={goToNext}
        >
          &#8594;
        </button>
      </div>

      <img
        className={styles.iconsolidx}
        alt="Close"
        src="/generics/close.png"
        onClick={() => setImageViewer(false)}
      />
    </>
  );
};

export default ImageViewer;
