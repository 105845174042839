import React, { useState } from "react";
import styles from "../css/Registration_Owner/RegistrationOwnerDetails.module.css";
import { RegistrationData, RootState } from "../store/types";
import { updateRegistrationData } from "../store/actions/registrationActions";
import { connect, useSelector } from "react-redux";

export type AddOrganizationProps = {
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
  onNextButtonKeyDown: () => void;
  onChangeRouteNextButtonClick?: () => void;
  handleUpdateRegistrationData: (data: RegistrationData) => void;
  role: string;
  registerData: RegistrationData;
  onClick?: () => void;
};

type DotByStateType = {
  [key: string]: boolean;
};

const mapDispatchToProps = {
  updateRegistrationData,
};

const mapStateToProps = (state: RootState) => ({
  registrationData: state.registrationData,
});

const RegistrationOwnerDetails: React.FC<
  AddOrganizationProps &
    ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps
> = ({
  onNextButtonClick,
  onBackButtonClick,
  updateRegistrationData,
  registrationData,
  ...props
}) => {
  const reduxState = useSelector((state: RootState) => state);
  const [companyStateHead, setCompanyStateHead] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [isOrganizationsUpdated, setIsOrganizationsUpdated] = useState<boolean>(false);

  const handleNextClick = () => {
    onNextButtonClick?.();
  };

  const handleCompanyName = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newName = e.target.value;

    setCompanyName(newName);
    if (newName == "") setIsOrganizationsUpdated(false);
    else setIsOrganizationsUpdated(true);

    const updatedOrganizations = [...registrationData.organizations];

    updatedOrganizations[index] = {
      ...updatedOrganizations[index],
      name: newName,
    };
    updateRegistrationData({
      ...registrationData,
      organizations: updatedOrganizations,
    });
  };

  const handleBackButtonClick = () => {
    onBackButtonClick();
  };

  return (
    <div className={styles.vehicleOwner03}>
      <div className={styles.backButtonParent}>
        <div
          className={styles.textButton}
          onClick={handleBackButtonClick}
          style={{ cursor: "pointer" }}
        >
          <img className={styles.icon} alt="" src="/generics/icon5.svg" />
          <div className={styles.reefer}>Back</div>
        </div>
        <div className={styles.stepcircleWithTextParent}>
          <div className={styles.stepcircleWithText}>
            <div className={styles.stepcircle}>
              <img className={styles.icon} alt="" src="/generics/check1.svg" />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Sign up</div>
            </div>
          </div>
          <div className={styles.instanceChild} />
          <div className={styles.stepcircleWithText1}>
            <div className={styles.stepcircle1}>
              <img className={styles.icon} alt="" src="/generics/check.svg" />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Company details</div>
            </div>
          </div>
          <div className={styles.instanceChild} />
          <div className={styles.stepcircleWithText2}>
            <div className={styles.stepcircle2}>
              <div className={styles.dot} />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>Verification</div>
            </div>
          </div>
        </div>
        <div className={styles.userNameParent}>
          <div className={styles.userName}>Your organization details</div>
          <div
            className={styles.inputText3}
          >Please provide your Organization information accurately, it will be used in all your communications on the platform.&nbsp;</div>
        </div>
        <div className={styles.textButtonGroup}>
          <div className={styles.textButton1}>
            <img className={styles.icon} alt="" src="/generics/icon6.svg" />
            <div className={styles.reefer}>Back&nbsp;</div>
          </div>
          <div className={styles.frameWrapper}>
            <div className={styles.inputWithLabelParent}>
              <div className={styles.inputWithLabel}>
                <div className={styles.reefer}>Company name&nbsp;</div>
                <div className={styles.basicInput}>
                  <input
                    type="text"
                    className={styles.inputField}
                    value={companyName}
                    placeholder="Company Name"
                    style={{ border: "none", width: "90%" }}
                    onChange={(e) => handleCompanyName(e, 0)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameChild} />
          <div className={styles.frameChild} />
          <div
            className={`${styles.button1} hoverButtons`}
            style={{
              backgroundColor: companyName !== "" ? "#fa6f32" : "#f1f5f9",
              color: companyName !== "" ? "white" : "initial",
            }}
            onKeyDown={() => {}}
            role="button"
            onClick={handleNextClick}
          >
            <div className={styles.text}>Search company</div>
          </div>

          <div className={styles.inputTextContainer}>
            <div className={styles.inputText8}>Length</div>
            <div className={styles.tagParent}>
              <div className={styles.tag}>
                <img
                  className={styles.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer}>Small</div>
              </div>
              <div className={styles.tag}>
                <img
                  className={styles.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer}>Large</div>
              </div>
            </div>
          </div>
          <div className={styles.inputTextContainer}>
            <div className={styles.inputText8}>Lift Gate</div>
            <div className={styles.tagParent}>
              <div className={styles.tag}>
                <img
                  className={styles.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer}>Rail Gate</div>
              </div>
              <div className={styles.tag}>
                <img
                  className={styles.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer}>Tuck Under</div>
              </div>
              <div className={styles.tag}>
                <img
                  className={styles.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer}>No Lift Gate</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationOwnerDetails);
